export default function useConfig() {
  return {
    intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
    hubspotId: process.env.NEXT_PUBLIC_HUBSPOT_ID,
    hotjarSiteId: process.env.NEXT_PUBLIC_HOTJAR_SITE_ID,
    googleAnalyticsMeasurementId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_MEASUREMENT_ID,
    googleAnalyticsTrackingId: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_TRACKING_ID,
    googleAdsId: process.env.NEXT_PUBLIC_GOOGLE_ADS_ID,
    googleAdsFreeTrialRequestConversionId: process.env.NEXT_PUBLIC_GOOGLE_ADS_FREE_TRIAL_REQUEST_CONVERSION_ID,
    googleAdsDemoRequestConversionId: process.env.NEXT_PUBLIC_GOOGLE_ADS_DEMO_REQUEST_CONVERSION_ID,
    googleAdsCallbackRequestConversionId: process.env.NEXT_PUBLIC_GOOGLE_ADS_CALLBACK_REQUEST_CONVERSION_ID,
    whatConvertsProfileId: process.env.NEXT_PUBLIC_WHAT_CONVERTS_PROFILE_ID,
    facebookPixelId: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
    linkedinPartnerId: process.env.NEXT_PUBLIC_LINKEDIN_PARTNER_ID,
  }
}
